import { NgModule } from '@angular/core';
import { AutosizeDirective } from './autosize.directive';

@NgModule({
  declarations: [AutosizeDirective],
  imports: [
  ],
  exports: [AutosizeDirective]
})
export class AutosizeModule { }
